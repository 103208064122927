import "./App.css";
import { useState } from "react";
import { SelectronMe } from "./Pages/SelectronMe";
import { Transverse } from "./Pages/Transverse";
import { QuantumMachine } from "./Pages/QuantumMachine";
import { ProjectAtom } from "./Pages/ProjectAtom";
import { About } from "./Pages/About";
import { Contact } from "./Pages/Contact";

function App() {
  const [visibility, setVisibility] = useState(false);

  const handleCollapse = () => {
    setVisibility(!visibility);
  };

  const [ActivePage, SetActivePage] = useState("selectron.me");

  const openWindow = (name) => {
    SetActivePage([name]);
  };

  const Pages = {
    "selectron.me": <SelectronMe />,
    "Quantum Machine": <QuantumMachine />,
    Transverse: <Transverse />,
    "Project Atom": <ProjectAtom />,
    About: <About />,
    Contact: <Contact />,
  };

  return (
    <div className="main">
    
      <div className="midContent">
        <div className="navBar">
        <div className="topBar">
        <div onClick={(e) => openWindow("selectron.me")} className="pageName">
          selectron.me
        </div>
        <div className="pageDescription">design & development</div>
      </div>
          <div className="collapse">
            <div className="navItem" onClick={handleCollapse}>
              Projects
            </div>
            {visibility ? (
              <div className="collapsible">
                <div
                  onClick={(e) => openWindow("Quantum Machine")}
                  className="collapsibleItem"
                >
                  Quantum Machine
                </div>
                <div
                  onClick={(e) => openWindow("Transverse")}
                  className="collapsibleItem"
                >
                  Transverse
                </div>
                <div
                  onClick={(e) => openWindow("Project Atom")}
                  className="collapsibleItem"
                >
                  Project Atom
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>

          <div onClick={(e) => openWindow("About")} className="navItem">
            About
          </div>
          <div onClick={(e) => openWindow("Contact")} className="navItem">
            Contact
          </div>
        </div>
        <div className="contentContainer">{Pages[ActivePage]}</div>
      </div>
      <div className="bottomBar">
        <p>Anything in the bottom will be here</p>
      </div>
    </div>
  );
}
export default App;
