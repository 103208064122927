import { BsInstagram, BsGithub, BsLinkedin } from "react-icons/bs";
import { FaTiktok } from "react-icons/fa";
import { FiMail } from "react-icons/fi";

export const Contact = (props) => {
  return (
    <div className="pageContent">
      <div className="contactContent">
        <a
          className="contact"
          href="http://instagram.com/susyexists"
          target="_blank"
          rel="noopener noreferrer"
        >
          <BsInstagram />
          <p>Instagram</p>
          <p>@susyexists</p>
        </a>
        <a
          className="contact"
          href="http://tiktok.com/susyexists"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaTiktok />
          <p>Tiktok</p>
          <p>@susyexists</p>
        </a>
        <a
          className="contact"
          href="http://github.com/susyexists"
          target="_blank"
          rel="noopener noreferrer"
        >
          <BsGithub />
          <p>Github</p>
          <p>@susyexists</p>
        </a>
       
        <a href="mailto:susy@selectron.me" className="contact">
          <FiMail />
          <p>E-mail</p>
          <p>susy@selectron.me</p>
        </a>
      </div>
    </div>
  );
};
